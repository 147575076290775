import './help_style.scss';
import small from './image/small.webp';
import average from './image/average.webp';
import big from './image/big.webp';


const Help = () => {

  return (
  <div className="help_block">
    <div className="container_help">
      <div className="blue_block">
        <img src={big} alt="list" className="big_list"/>
        <img src={average} alt="list" className="average_list"/>
        <img src={small} alt="list" className="small_list"/>
        <div className="block_text">
          <div className="help_title">Нужна помощь?</div>
          <div className="help_intro">Напишите в службу заботы и мы обязательно поможем!</div>
        </div>
        <a href="http://promolodost.online/cms/system/contact" target="_blank" className="button_green button_two">В СЛУЖБУ ЗАБОТЫ</a>
      </div>
    </div>
  </div>
  );
}

export default Help;
