import './main_style.scss';
import logo from './image/logo.svg';
import bg_list from './image/bg_list.webp';
import list_2 from './image/list_2.webp';
import bg_natasha from './image/natasha.webp';
import look_video from './image/look_video.svg';
import kust from './image/kust.webp';
import pink from './image/pink.webp';
import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import PopUp from '../pop_up/PopApp'
import { motion } from 'framer-motion'

const Main = ({contact, clickContact, url_bot}) => {
  
   
  document.title = ("Зарядки молодости")

  const [play, setPlay] = useState(false)
  
  const clickPlay = () => {
    setPlay(!play)
  }
  
  
  
  
  
  return (
  <div className="main_block">
    
    <CSSTransition in={play} timeout={300} 
    classNames='alert' unmountOnExit>

    <div className="bg_play">
      <div className="pop_up_play">
        <svg style={{cursor:"pointer"}} className="close_play" onClick={clickPlay} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
          <path d="M19.5563 17.5L25.8271 11.2437C26.1017 10.9691 26.256 10.5967 26.256 10.2083C26.256 9.81996 26.1017 9.44751 25.8271 9.1729C25.5525 8.89829 25.1801 8.74402 24.7917 8.74402C24.4033 8.74402 24.0309 8.89829 23.7563 9.1729L17.5 15.4437L11.2438 9.1729C10.9692 8.89829 10.5967 8.74402 10.2084 8.74402C9.82001 8.74402 9.44756 8.89829 9.17295 9.1729C8.89834 9.44751 8.74407 9.81996 8.74407 10.2083C8.74407 10.5967 8.89834 10.9691 9.17295 11.2437L15.4438 17.5L9.17295 23.7562C9.03626 23.8918 8.92777 24.0531 8.85373 24.2308C8.7797 24.4085 8.74158 24.5991 8.74158 24.7917C8.74158 24.9842 8.7797 25.1748 8.85373 25.3525C8.92777 25.5302 9.03626 25.6915 9.17295 25.8271C9.30852 25.9638 9.46981 26.0722 9.64753 26.1463C9.82524 26.2203 10.0158 26.2584 10.2084 26.2584C10.4009 26.2584 10.5915 26.2203 10.7692 26.1463C10.9469 26.0722 11.1082 25.9638 11.2438 25.8271L17.5 19.5562L23.7563 25.8271C23.8919 25.9638 24.0531 26.0722 24.2309 26.1463C24.4086 26.2203 24.5992 26.2584 24.7917 26.2584C24.9842 26.2584 25.1748 26.2203 25.3525 26.1463C25.5303 26.0722 25.6915 25.9638 25.8271 25.8271C25.9638 25.6915 26.0723 25.5302 26.1463 25.3525C26.2204 25.1748 26.2585 24.9842 26.2585 24.7917C26.2585 24.5991 26.2204 24.4085 26.1463 24.2308C26.0723 24.0531 25.9638 23.8918 25.8271 23.7562L19.5563 17.5Z" fill="#B1B1B1"/>
        </svg>
        <iframe className="youtube" src="https://www.youtube.com/embed/4t3wYMRMCc4?si=ETPymz45k1WPYlBe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
    
    </CSSTransition>

    
    <PopUp contact={contact} clickContact={clickContact} url_bot={url_bot} />
    

    <div className="container_main">
    <div className="mig">
      <nav></nav>
    </div>
      <div className="frame_grey">
        <p>Быстрое, простое и легкое решение <span>за 20 минут</span> каждое утро: </p>
        <ul className="list_uprazhneniya">
          <li>Подтянуть тело и включить мышцы</li>
          <li>Запустить метаболизм и лимфоток</li>
          <li>Создать красивую фигуру</li>
          <li>Активировать омоложение на клеточном уровне</li>
          <li>Получить заряд бодрости, энергии и уверенности в себе</li>
        </ul>
        <a onClick={clickContact} style={{cursor:"pointer"}} target="_blank" className="button_green button_one">ПОПРОБОВАТЬ БЕСПЛАТНО</a>
        <img className="bg_list" src={bg_list} alt="Промолодость"/>
        <img className="natasha" src={bg_natasha} alt="Наташа"/>
        <img className="kust" src={kust} alt="Куст"/>
        <svg className="frame_blure" xmlns="http://www.w3.org/2000/svg" width="1920" height="653" viewBox="0 0 1920 653" fill="none">
          <g filter="url(#filter0_f_40_480)">
            <path d="M1920 44.4782V609H0V608.941L1920 44.4782Z" fill="#F6F6F6"/>
          </g>
          <defs>
            <filter id="filter0_f_40_480" x="-44" y="0.47821" width="2008" height="652.522" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="22" result="effect1_foregroundBlur_40_480"/>
            </filter>
          </defs>
        </svg>
        <img className="list_2" src={list_2} alt="Куст"/>
        <div className="text">Делая эти практики каждое утро, вы будете входить в особое состояние — красивой, здоровой, молодой и любящей себя женщины. И каждый день вы будете проживать в этом состоянии, удивляясь, как меняется в лучшую сторону ваша жизнь.</div>
        <img className="pink" src={pink} alt="Куст"/>
    
        
          
          <motion.div className="button_circle" onClick={clickPlay}

            initial={{ opacity: 0.5, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1.1,
              ease: [0, 0.4, 0.2, 1.01],
              repeat: Infinity,
              repeatType: 'reverse'
            }}

    
            >
    
            <img className="look_video" src={look_video} alt="смотреть видео"/>
    
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="53" viewBox="0 0 42 53" fill="none">
              <path d="M11.8255 2.0218C7.06964 -1.27071 0.573105 2.13316 0.573105 7.91751V45.0824C0.573105 50.8668 7.06964 54.2707 11.8255 50.9782L38.6668 32.3957C42.7856 29.5442 42.7856 23.4557 38.6668 20.6043L11.8255 2.0218Z" fill="white"/>
            </svg>
          </motion.div>
    
      </div>
    </div>
  </div>
  );
}

export default Main;
