import { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from "axios";
import New from '../components/clients/Clients'


const Client = () => {

  const [notes, setNewNotes] = useState(null)

  useEffect(() => {
    getNotes()
  }, [])

  function getNotes() {
    axios({
      method: "GET",
      url:'/api/new_client/',
    }).then((response)=>{
      const data = response.data
      setNewNotes(data)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    })}
  
  
  return (
      <div className="container_table">
        <div className="zagolovok">
          <h3>Заявки</h3>
          <NavLink to={'/logout/'}>Выйти</NavLink>
        </div>
        <div class="table_client">
          <div>Имя</div>
          <div>Email</div>
          <div>Телефон</div>
          
    
          {
            notes && notes.map(note => <New
              key={note.id}
              name={note.name}
              email={note.email}
              phone={note.phone}
              />
            )
          }
          
        </div>
    
      </div>
  )
};

export default Client;
