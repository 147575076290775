import Main from '../components/main/Main'
import ForYou from '../components/for_you/ForYou'
import WhyCourse from '../components/why_course/WhyCourse'
import SostavCourse from '../components/sostav_course/SostavCourse'
import Author from '../components/author/Author'
import Price from '../components/price/Price'
import Review from '../components/review/Review'
import Help from '../components/help/Help'
import Footer from '../components/footer/Footer'
import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Helmet } from 'react-helmet';


const IndexMain = () => {
  
  const [contact, setContact] = useState(false)
  
  const clickContact = () => {
    setContact(!contact)
  }
  
  
  return (
    <div className="App">
    
    <Helmet>
      <html lang='ru'/>
    
     {/* google  */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-X82DWHKNLD"></script> 
      <script> 
        {`window.dataLayer = window.dataLayer || []; 
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-X82DWHKNLD');`} 
      </script>
    
     {/* Нэйтс  */}
    <script>
        {`(function(a,g,b,c){a[c]=a[c]||function(){
            "undefined"!==typeof a.ipromNS&&a.ipromNS.execute?a.ipromNS.execute(arguments):(a[c].q=a[c].q||[]).push(arguments)};
            var k=function(){var b=g.getElementsByTagName("script")[0];return function h(f){var e=f.shift();a[c]("setConfig",{server:e});
                var d=document.createElement("script");0<f.length&&(d.onerror=function(){a[c]("setConfig",{blocked:!0});h(f)});
                d.src="https://cdn."+e+"/ipromNS.js";d.async=!0;d.defer=!0;b.parentNode.insertBefore(d,b)}}(),e=b;"string"===typeof b&&(e=[b]);k(e)
        })(window, document,["iprom.net"],"_ipromNS"); var cookieConsent = false;
        try{
            cookieConsent = true;  //connect with your cookie consent framework;
        }catch(e){console.log('CC not found');}
        _ipromNS('init', {
            'sitePath' : [ 'promolodostspace','index' ],
            'cookieConsent' : cookieConsent
        });`}
    </script>
    
    </Helmet>
    
    <Main contact={contact} clickContact={clickContact} url_bot={"https://t.me/Promolodost_club_bot"}/>
    <ForYou />
    <WhyCourse />
    <SostavCourse contact={contact} clickContact={clickContact}/>
    <Author />
    <Price contact={contact} clickContact={clickContact}/>
    <Review />
    <Help />
    <Footer />
          
    </div>
  );
}

export default IndexMain;
