import { RouterProvider, createBrowserRouter, Link } from "react-router-dom";
import IndexMain from '../pages/IndexMain'
import IndexMainRus from '../pages/IndexMainRus'
import Login from '../pages/Login'
import Logout from '../pages/Logout'
import { useAuth } from "../provider/authProvider"
import { ProtectedRoute } from "./ProtectedRoute";
import Client from '../pages/Client';


const Routes = () => {
  const { token } = useAuth();
  
  
  //Маршруты доступные для всех пользователей
  const routesForPublic = [
    {
      path: "/",
      element: <IndexMain />
    },
    {
      path: "/ru",
      element: <IndexMainRus />,
    },
    {
      path: "/logout",
      element: <Logout />,
    }
  ];

  
  //Маршруты доступные только для аутентификации
  const routesForAuthenticateOnly = [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/adm/client",
          element: <Client />
        }
      ]
    }
  ]

  
 //Маршруты доступные только для неавторизированных пользователей
  const routesForNotAuthenticateOnly = [
    {
      path: "/log",
      element: <Login />
    }
  ];
  



  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticateOnly: []),
    ...routesForAuthenticateOnly,
  ]);
 
return <RouterProvider router={router} />

};



export default Routes;
