import lists from './image/lists.webp' 
import './price_style.scss';


const Price = ({contact, clickContact}) => {

  
  return (
  <div className="price_block">
    
    <div className="container_price">
      <div className="title_price">Цена и доступ</div>
    
      <div className="price">
        <div className="lo_price left_price">
          <img src={lists} className="lists" alt="лист"/>
          <div className="rub">3900 рублей</div>
          <div className="uslovie">при оплате из России</div>
        </div>
        <div className="lo_price right_price">
          <div className="rub">59 евро</div>
          <div className="uslovie">при оплате из других стран</div>
        </div>
      </div>

      <div className="intro">Уроки открываются сразу послe оплаты,<br/> доступ к занятиям 5 недель</div>

      <a onClick={clickContact} style={{cursor:"pointer"}} className="button_green button_two">КУПИТЬ КУРС</a>
      
    </div>
    
  </div>
  );
}

export default Price;
