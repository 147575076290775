import './why_course_style.scss';
import one from './image/one.webp';
import two from './image/two.webp';
import three from './image/three.webp';
import fore from './image/fore.webp';
import five from './image/five.webp';
import green_list from './image/green_list.webp';

const WhyCourse = () => {

  return (
  <div className="why_course_block">
    
    <svg xmlns="http://www.w3.org/2000/svg" width="1919" height="1054" viewBox="0 0 1919 1054" fill="none">
    <path d="M0 137.5C736.871 -47.6993 1157.6 -42.8304 1920 137.5V919C1920 919 1338.59 1054 960 1054C581.408 1054 0 919 0 919V137.5Z" fill="url(#paint0_linear_40_498)"/>
    <defs>
    <linearGradient id="paint0_linear_40_498" x1="960" y1="0.420166" x2="960" y2="1054" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F6F6F6"/>
    <stop offset="1" stopColor="#F6F6F6" stopOpacity="0"/>
    </linearGradient>
    </defs>
    </svg>
    <img src={green_list} className="green_list" alt="зарядка"/>
    
    <div className="container_why_course">
    
      <div className="title_why_course">Почему именно этот курс?</div>
     
      <div className="container_card">
        <div className="card">
          <img src={one} className="image_card" alt="зарядка"/>
          <div className="text_card">
            <div className="title_card">Просто</div>
            <p>Просто, быстро, легко и понятно - подходит как для новичков, так и для тех, кто давно дружит со спортом</p>
          </div>
        </div>
    
        <div className="card">
          <img src={two} className="image_card" alt="зарядка"/>
          <div className="text_card">
            <div className="title_card">Важно</div>
            <p>Практики выравнивают гормональный фон и помогают нервной системе</p>
          </div>
        </div>
    
        <div className="card">
          <img src={three} className="image_card" alt="зарядка"/>
          <div className="text_card">
            <div className="title_card">Идеально</div>
            <p>Помимо красоты вы восстанавливаете свое здоровье</p>
          </div>
        </div>
    
        <div className="card">
          <img src={fore} className="image_card" alt="зарядка"/>
          <div className="text_card">
            <div className="title_card">Быстро</div>
            <p>Всего за 20 минут включаются все системы организма и группы мышц</p>
          </div>
        </div>
    
        <div className="card">
          <img src={five} className="image_card" alt="зарядка"/>
          <div className="text_card">
            <div className="title_card">Полезно</div>
            <p>Особый нейрофизиологический подход решает вопросы психосоматики</p>
          </div>
        </div> 
      </div>
    
    </div>
  </div>
  );
}

export default WhyCourse;
