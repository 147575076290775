import slide_1 from './image/1.webp' 
import slide_2 from './image/2.webp' 
import slide_3 from './image/3.webp' 
import slide_4 from './image/4.webp' 
import slide_5 from './image/5.webp' 
import slide_6 from './image/6.webp' 
import slide_7 from './image/7.webp' 
import slide_8 from './image/8.webp' 
import slide_9 from './image/9.webp' 
import slide_10 from './image/10.webp' 
import bonus from './image/bonus.webp' 
import clock from './image/clock.svg' 
import arrow from './image/arrow.svg' 
import './sostav_course_style.scss';
import Carousel from 'nuka-carousel'



const SostavCourse = ({contact, clickContact}) => {

  const params = {
    wrapAround: true,
    autoplay: true,
    autoplayInterval: 8000,
  }
  
  return (
  <div className="sostav_course_block">
    
    <div className="container_sostav_course">
    
      <div className="title_sostav_course">Состав курса</div>

      <div className="time">
        <img src={clock} alt="clock" className="clock"/>
        <div className="title_sostav_course">10 уроков по 20 минут</div>
        <img src={arrow} alt="arrow" className="arrow"/>
      </div>
      
      <Carousel {...params} 
        
        renderCenterLeftControls={({ previousSlide }) =>(
            <svg onClick={previousSlide} xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
              <g opacity="0.35">
                <path d="M53.3333 67.9999L27.8856 42.5522C26.8442 41.5108 26.8442 39.8224 27.8856 38.781L53.3333 13.3333" strokeWidth="12" strokeLinecap="round"/>
              </g>
            </svg>
        )}
    
        renderCenterRightControls={({ nextSlide }) =>(
            <svg onClick={nextSlide} xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
              <g opacity="0.35">
                <path d="M26.6667 12.0001L52.1144 37.4478C53.1558 38.4892 53.1558 40.1776 52.1144 41.219L26.6667 66.6667" stroke="#FF9898" strokeWidth="12" strokeLinecap="round"/>
              </g>
            </svg>
        )}>
      
        <img src={bonus} alt="fdfssd" className="img_car"/>
        <img src={slide_1} alt="fdfssd" className="img_car"/>
        <img src={slide_2} alt="fdfssd" className="img_car"/>
        <img src={slide_3} alt="fdfssd" className="img_car"/>
        <img src={slide_4} alt="fdfssd" className="img_car"/>
        <img src={slide_5} alt="fdfssd" className="img_car"/>
        <img src={slide_6} alt="fdfssd" className="img_car"/>
        <img src={slide_7} alt="fdfssd" className="img_car"/>
        <img src={slide_8} alt="fdfssd" className="img_car"/>
        <img src={slide_9} alt="fdfssd" className="img_car"/>
        <img src={slide_10} alt="fdfssd" className="img_car"/>
        
      </Carousel>
    
        <a onClick={clickContact} style={{cursor:"pointer"}} className="button_green button_two">ПОПРОБОВАТЬ БЕСПЛАТНО</a>
    
           
    </div>
  </div>
  );
}

export default SostavCourse;
