import React, { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom'
import './clients_style.scss'

function New(props){

  return (
    <>
    <div>{ props.name }</div>
    <div>{ props.email }</div>
    <div>{ props.phone }</div>
    </>
  )
}

export default New;
