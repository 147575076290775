import list_left from './image/list_left.webp' 
import list_right from './image/list_right.webp' 
import './review_style.scss';
import Carousel from 'nuka-carousel'



const Review = () => {

  const params = {
    wrapAround: true,
    autoplay: true,
    autoplayInterval: 8000,
  }
  
  return (
  <div className="review_block">
    
    <div className="container_review">
    
      <div className="title_sostav_course">Отзывы</div>
      <img src={list_right} alt="лист" className="list_right"/>

      <Carousel {...params} 
        
        renderCenterLeftControls={({ previousSlide }) =>(
            <svg onClick={previousSlide} xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
              <g opacity="0.35">
                <path d="M53.3333 67.9999L27.8856 42.5522C26.8442 41.5108 26.8442 39.8224 27.8856 38.781L53.3333 13.3333" strokeWidth="12" strokeLinecap="round"/>
              </g>
            </svg>
        )}
    
        renderCenterRightControls={({ nextSlide }) =>(
            <svg onClick={nextSlide} xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
              <g opacity="0.35">
                <path d="M26.6667 12.0001L52.1144 37.4478C53.1558 38.4892 53.1558 40.1776 52.1144 41.219L26.6667 66.6667" stroke="#FF9898" strokeWidth="12" strokeLinecap="round"/>
              </g>
            </svg>
        )}>
      
        <div className="slide_one">
            <div className="text_review">
              <h4>Татьяна</h4>
              Доброе утро 🌞 
              Завела будильник пораньше, чтобы делать упражнения!
              Очень понравились.
              Прям ХОЧЕТСЯ делать.
              Брала уже несколько пробных у других специалистов, не зашли.
              Была в поиске, чтобы упражнения я нравились и чтобы спикер откликался!
              И мне кажется я нашла❤️
              На утреннюю практику подключились даже дети!
              Вчера вечером делала на тело, сегодня утром чувствую прям свои стопы, как будто ночью был массаж у них)
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
    
        <div className="slide_one">
            <div className="text_review">
              <h4>Камила</h4>
              Добрый вечер) 
              Сегодня сделала 5-ый урок . Договорившись со своим телом. 
              Мой живот который выпирал много лет просто пропал за эти 5 занятий . Совсем не хочется сутулится . За столько лет я впервые кайфую от своей груди ( как она поднялась ) грудь у меня большая 3-й размер при объеме грудной клетки 70. 
              Из за сутулости она висела . Птоз 3-й степени . И вот пару занятий и я ее не узнаю ) 
              Спасибо Наталия . За столь шикарные занятия . 
              Всем хорошего настроения.
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
    
        <div className="slide_one">
            <div className="text_review">
              <h4>Наталья</h4>
              Это наверное первый курс, когда я занималась ежедневно, специально планируя время под занятия. Очень дисциплинирует! За эти три недели я чувствую, как мое тело стало более сильным, гибким, расширилась амплитуда движений. Люблю спать на животе, но головой всегда лежала в одну сторону, на другую было неудобно, сейчас могу повернуть шею любым боком. Мои обьемы на животе и вес не ушли, но я не переживаю по этому поводу.
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
  
        <div className="slide_one">
            <div className="text_review">
              <h4>Елена</h4>
              Девочки, всех Благовещеньем! А я тоже хочу похвастаться! У меня на ногах выпрямились пальцы, подтянулось все тело, ноги постройнели! Я реально вижу результат! И это из-за легких тренировок, которые приносят радость и удовольствие, а не изнурительных качалок  в зале! Я каждое утро любуюсь собой в зеркале! И это намерение я написала на курсе про ноги. Ещё раз: "Наташа, спасибо огромное, что вы есть!"
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
  
        <div className="slide_one">
            <div className="text_review">
              <h4>Надежда</h4>
              Доброе утро девочки 🥰 Я тоже с субботы начала по второму кругу. Я жажду изменений, поэтому делаю и утром и вечером. Правда в кайф, утром как утреннюю зарядку, чтобы зарядиться на день, а вечером, чтобы отпустить все дела и проблемы и раствориться в своем теле. Вот удивительная штука, занятия одни и те же, а от поставленных целей "подстраиваются" под запрос. Волшебство Натальи🪄🤩
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
  
        <div className="slide_one">
            <div className="text_review">
              <h4>Елена</h4>
              @natalia_promolodost какое же счастье, что я встретила вас в инсте и услышала!!! Каждый раз занимаясь я кайфую по полной!!! Сегодня сделала 12 занятие и с таким удовольствием работала с мячиками, было очень приятно больно! Да, именно так, приятно больно и потом глубокое ощущение удовольствия внутри тела! 
              По кг я не похудела, НО тело подтянулось и очень классное ощущение от себя любимой! 
              Ещё и ещё раз вас благодарю 🙏🏻
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
  
        <div className="slide_one">
            <div className="text_review">
              <h4>Надежда</h4>
              Девочки, всем привет. У меня за три дня занятий реально подтянулся животик (мужу показываю, он говорит зачем ты его втянула😂), ещё весы меня порадовали - 2 кг наверное жикость уходит. Но даже не всё это радует, а то что такоооой прилив энергии, делаю все с удовольствием и дома и на работе.  Настроение великолепное, я тоже великолепна. ❤️ Девочки, кто ещё не начал обязательно себя заставьте, потом вольетесь не оторваться🙂
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
  
        <div className="slide_one">
            <div className="text_review">
              <h4>Ирина</h4>
              Добрый вечер))) Присоединяюсь к восторженным отзывам))) я уже писала, что очень прониклась занятиями, не все получается и не всегда) работа и малыш 1,9)) занимаюсь ближе к полуночи, но все-равно с большим удовольствием ☺️ Дочь надо мной смеётся, особенно когда уже лежу в позе расслабления, это какой-то особенный кайф, а после занятия по коррекции таза просто летала🥰 Вообще, это конечно целая философия и преподавание любви к себе 🔥😍 Иду на следующий курс 100%, постараюсь ещё больше времени посвящать себе☺️ Спасибо 🤗
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
  
        <div className="slide_one">
            <div className="text_review">
              <h4>Наталья</h4>
              Наталья, слушаю Вас сейчас и думаю, сколько же в Вас любви, желания сделать женщин более счастливыми, принимающими себя! Почему-то через данное видео я прям очень чувствую это. Либо я сейчас в каком-то определенном состоянии, что считываю это. Сегодня мне особенно классно Вас слушать 🤗, будто погрузилась в какое-то определённое состояние 🥰.
              В очередной раз благодарю Вас за появление в моей жизни несколько лет назад 🙏😍.
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
  
        <div className="slide_one">
            <div className="text_review">
              <h4>Наталия</h4>
              Доброе утро! Вчера утром и сегодня утром сделала утреннюю практику для шеи и вчера вечернюю практику для ног и для шеи. Сегодня я вижу в зеркале такое помолодевшее, посветлевшее лицо! Интересно, что будет в конце курса??? И какая же Наталья красивая на практиках по лицу! Наталья, тебе очень идёт синий цвет, добавляет в твоему внутреннему свету шикарный оттенок! Первая реакция на начало уроков - УЛЫБКА И СЧАСТЬЕ!
            </div>
          <img src={list_left} alt="лист" className="list_left"/>
        </div>
        
      </Carousel>
    
    
    </div>
  </div>
  );
}

export default Review;
