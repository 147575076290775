import lists from './image/lists.webp' 
import './price_style.scss';


const Price = ({contact, clickContact}) => {

  
  return (
  <div className="price_block">
    
    <div className="container_price">
      <div className="title_price">Цена и доступ</div>
    
      <div className="price">
        <div className="lo_price right_price">
          <div className="rub">39 евро</div>
        </div>
      </div>

      <div className="intro">Доступ к занятиям 5 недели</div>

      <a onClick={clickContact} style={{cursor:"pointer"}} className="button_green button_two">ПОПРОБОВАТЬ БЕСПЛАТНО</a>
      
    </div>
    
  </div>
  );
}

export default Price;
