import './footer_style.scss';
import { Link, NavLink } from 'react-router-dom'


const Footer = () => {

  return (
  <div className="footer_block">
    <div className="container_footer">
      <NavLink to={'https://www.iubenda.com/privacy-policy/62131983/cookie-policy'}>Пользовательское соглашение</NavLink>
      <NavLink to={'https://www.iubenda.com/privacy-policy/62131983'}>Политика конфиденциальности</NavLink>
    </div>
  </div>
  );
}

export default Footer;
