import './for_you_style.scss';
import one from './image/1.svg';
import two from './image/2.svg';
import three from './image/3.svg';
import four from './image/4.svg';
import five from './image/5.svg';
import one_pink from './image/one_pink.webp';
import two_pink from './image/two_pink.webp';

const ForYou = () => {

  return (
  <div className="for_you_block">
    <div className="container_for_you">
    
      <div className="title_for_you">Это для вас, если вы</div>
    
      <div className='numbers'>
        <div className="num">
          <img src={one} className="one" alt="один"/>
          <p>Много времени отдаете работе и семье, а на себя не остается</p>
        </div>
        <div className="num">
          <img src={two} alt="два"/>
          <p>Хотите начать о себе заботиться и ищете простой, быстрый и эффективный способ</p>
        </div>
        <div className="num">
          <img src={three} alt="три"/>
          <p>Уже любите или только хотите начать любить свое тело и понимаете важность заботы о нем</p>
        </div>
        <div className="num">
          <img src={four} alt="четыре"/>
          <p>Знаете, что регулярные занятия и дисциплина поможет вам подтянуть все остальные сферы жизни</p>
        </div>
        <div className="num">
          <img src={five} alt="пять"/>
          <p>Хотите идти по жизни уверенной грациозной походкой и ловить восхищенные взгляды</p>
        </div>
        <img className="one_pink" src={one_pink} alt="лист"/>
        <img className="two_pink" src={two_pink} alt="лист"/>
      </div>
    
    </div>
  </div>
  );
}

export default ForYou;
