import natasha from './image/natasha.webp' 
import list_one from './image/l_one.webp' 
import list_two from './image/l_two.webp' 
import list_three from './image/l_three.webp' 
import './author_style.scss';


const Author = () => {

  
  return (
  <div className="author_block">
    
    <div className="container_author">
      <div className="container_text">
        <div className="title_author"> 
          Автор курса
          <img src={list_three} className="list_three" alt="лист"/>
        </div>
        <div className="title_natasha">Наталья Баньяц</div>
        <ul className="list_regalii">
          <li>Доктор натуропатии и аюрведы</li>
          <li>Основатель международного клуба Pro-молодость</li>
          <li>Разработчик методики безоперационного омоложения лица SMAS-скульптура</li>
          <li>Исследователь образа жизни долгожителей</li>
          <li>В данный момент получаю еще одно высшее образование в Лондонском колледже биомедицины</li>
          <li>Antiage эксперт</li>
        </ul>
      </div>
      <div className="natasha_two">
        <img src={natasha}  className="natasha_circle" alt="Наталья"/>
        <img src={list_one} className="list_one" alt="лист"/>
        <img src={list_two} className="list_two" alt="лист"/>
      </div>
    
    </div>
  </div>
  );
}

export default Author;
