import Main from '../components/main/Main'
import ForYou from '../components/for_you/ForYou'
import WhyCourse from '../components/why_course/WhyCourse'
import SostavCourse from '../components/sostav_course/SostavCourse'
import Author from '../components/author/Author'
import Price from '../components/price_ru/Price'
import Review from '../components/review/Review'
import Help from '../components/help/Help'
import Footer from '../components/footer/Footer'
import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'


const IndexMainRus = () => {
  
  const [contact, setContact] = useState(false)
  
  const clickContact = () => {
    setContact(!contact)
  }
  
  
  return (
    <div className="App">
    <Main contact={contact} clickContact={clickContact} url_bot={"https://t.me/Club_Pro_molodost_bot"}/>
    <ForYou />
    <WhyCourse />
    <SostavCourse contact={contact} clickContact={clickContact}/>
    <Author />
    <Price contact={contact} clickContact={clickContact}/>
    <Review />
    <Help />
    <Footer />
          
    </div>
  );
}

export default IndexMainRus;
